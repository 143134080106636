import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Message from "../components/message"

import Lesson from "../components/lesson-table"
import PageLayout from "../components/page-layout"
import Schedule from "../components/schedule-table"
import FeeTable from "../components/fee-table"

import Theme from "../images/pexels-ivan-samkov-5255056.jpg"

export default function Home() {
    let data = useStaticQuery(graphql`
    query allAdultJson {
        allLessonsJson(filter: {for: {eq: "adult"}}) {
            edges {
                node {
                    time
                    name
                    week
                    row
                    color
                    grade
                }
            }
        }
        allFeeJson(filter: {for: {eq: "adult"}}) {
            edges {
                node {
                    title1
                    title2
                    title3
                    type
                    fee
                    count
                }
            } 
        }
    }
    `);

    return (
        <PageLayout title="大人クラス"
            subTitle={`いつまでも美しい姿勢で
                できるだけ健康でいるために`}
            image={Theme}>

            <Message>
                {`「動いている方が身体が楽になる」
                これは日々レッスンに通ってくださる生徒さんが、皆さん仰ることです。
                一人でストレッチをするのは続かなくても、週に一度でも皆でやれば、長く続けることができます。

                大人になれば、色々な立場や事情を抱えながら日々の生活があり、自分だけの時間というのはとても貴重なものになってきます。 
                子供の頃のような、ただの自分に戻る時間をつくるというのはとても有意義で素敵なことだと思います。 
                大人になってから趣味で始めた方が、たくさんいらっしゃいます。体力に自信のない方、 初心者の方が参加できるストレッチメインのクラスもございますので、安心してお問い合わせください。 `}
            </Message>

            <h3>大人クラス　スケジュール</h3>

            <Schedule data={data} />

            <Message>{`担当講師は、見学・体験レッスン時にお問い合わせください。`}</Message>

            <Lesson title="大人B">
                {`ストレッチ&基礎クラス
                --
                初心者、調整目的の方向け`}
            </Lesson>
            <Lesson title="大人A">
                {`初級〜初中級クラス
                --
                経験者、動きたい方向け`}
            </Lesson>
            <Lesson title="ポワント">
                {`通常レッスンを受講した方のみ`}
            </Lesson>

            <h3>大人クラス　月謝料金表</h3>

            <FeeTable data={data} />

            <Message>
                {`
                お休みした場合、他の日に振替レッスンができます。(レッスン細則をご確認ください。)
                ＊維持費は、コロナ等感染症対策衛生費及び冷暖房費として、１ヶ月につき1,100円を頂戴いたします。
                `}
            </Message>

            <div className="letter">
                <h4>バレエ教室に興味のある大人の方へ</h4>
                <Message>
                    {`
                    姿勢が悪いのはわかっているけれど、体が硬いしリズム感もないしレオタードは恥ずかしいし・・・などと思われるでしょうが、大丈夫です。
                    皆さん最初は同じ気持ちです。
                    初心者の方にはストレッチからお教えしますのでご安心ください。
                    20代〜50代頃からバレエを始めて、10年以上続けてこられた方もたくさんいらっしゃいます。
                    それは、本当に楽しいからなのだと思います。大人になって習い事をするのはとても良い刺激になります。
                    今さらと思わずに、今から充分楽しもうという気持ちでいらしてください。
                    私は14歳の時に初めてフランスでレッスンを受けました。
                    その時、大人のオープンクラスを見学する機会があり、真っ白な髪のおじいさまとおばあさまが、背筋を伸ばしてバーレッスンをしている姿に大変な衝撃を受けました。
                    その光景がとても美しかったので、今でも忘れられません。
                    小さい時にバレエをしていてブランクのある方も、このページを見た今が再開するチャンスです。
                    どうぞ、長くバレエを楽しんでください。
                    `}
                </Message>
            </div>
        </PageLayout>
    )
}