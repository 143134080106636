import * as React from "react"
import { Table } from 'react-bootstrap'

const Schedule = (data) => {

    const getSchedule = ({ data }) => {
        const rows = [];

        let maxLineNumber = Math.max.apply(Math, data.allLessonsJson.edges.map(element => { return element.node.row; }))
        for (let i = 1; i <= maxLineNumber; i++) {
            rows.push(<tr>{getScheduleRow(data, i)}</tr>);
        }

        return rows;
    }

    const getScheduleRow = (data, lineNumber) => {
        let items = [];
        let weeks = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

        weeks.forEach(week => {
            let lesson = data.allLessonsJson.edges.find(element => {
                return element.node.row === lineNumber && element.node.week === week;
            });

            if (lesson) {
                items.push(<td className={lesson.node.color + " table-" + lesson.node.grade + " exist"}>
                    <div className="time">{lesson.node.time}</div>
                    <div className="name">{lesson.node.name}</div>
                    <div className="teacher">{lesson.node.teacher}</div>
                </td>);
            }
            else {
                items.push(<td></td>);
            }
        })

        return items;

    }

    return (
        <>
            <Table className="schedule table-bordered" responsive>
                <thead>
                    <th scope="col">月曜日</th>
                    <th scope="col">火曜日</th>
                    <th scope="col">水曜日</th>
                    <th scope="col">木曜日</th>
                    <th scope="col">金曜日</th>
                    <th scope="col">土曜日</th>
                    <th scope="col">日曜日</th>
                </thead>
                <tbody>
                    {getSchedule(data)}
                </tbody>
            </Table>
        </>
    )
}

export default Schedule
