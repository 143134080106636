import * as React from "react"
import { Table } from "react-bootstrap"

const FeeTable = ({ data = {},
    enrollment = "入会金（税込）",
    keep = "維持費及び冷暖房費（年額・税込）",
    base = "基本料金（月額・税込）",
    extra = "チケット（税込）" }) => {

    const convert = (price) => {
        return String(Math.floor(price)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + "円";
    }

    const getPrice = (price, tax = 1) => {
        return price * tax;
    }

    const getUnitPrice = (price, count = 1) => {
        return price / count;
    }

    const getPriceText = (price, count) => {
        let taxIncluded = getPrice(price, 1.1);
        let text = convert(taxIncluded);
        if (count) {
            text += "（1レッスン" + convert(getUnitPrice(taxIncluded, count)) + "）"
        }
        return text;
    }

    const getRow = (data, type) => {
        let fee = data.allFeeJson.edges.find(element => {
            return element.node.type === type;
        });
        return getPriceText(fee.node.fee, fee.node.count);
    }

    const getRows = (data, type) => {
        const rows = [];
        let feeList = data.allFeeJson.edges.filter(element => {
            return element.node.type === type;
        });

        for (let i = 0; i < feeList.length; i++) {
            rows.push(<tr>{createFeeCells(feeList[i])}</tr>);
        }
        return rows;
    }

    const isVisible = (data, type) => {
        let feeList = data.allFeeJson.edges.filter(element => {
            return element.node.type === type;
        });

        return feeList.length > 0 ? "visible" : "hide";
    }

    const isVisibleExtra = isVisible(data, "extra");

    const createFeeCells = (fee) => {
        let items = [];

        if (fee) {
            if (fee.node.title1.length > 0) {
                items.push(<th>
                    <div className="main-title">{fee.node.title1}</div>
                    <div className="sub-title">{fee.node.title2}</div>
                </th>);
            }
            items.push(<td>
                <div className="time">{fee.node.title3}</div>
                <div className="name">{getPriceText(fee.node.fee, fee.node.count)}</div>
            </td>);
        }
        else {
            items.push(<th aria-label="none" />);
            items.push(<td></td>);
        }

        return items;
    }

    return (
        <>
            <Table className="enrollment table-light">
                <thead>
                    <tr>
                        <th className="table-dark">{enrollment}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{getRow(data, "enrollment")}</td>
                    </tr>
                </tbody>
            </Table>
            <Table className="keep">
                <thead>
                    <tr>
                        <th colspan="2" className="table-dark">{keep}</th>
                    </tr>
                </thead>
                <tbody>
                    {getRows(data, "keep")}
                </tbody>
            </Table>
            <Table className="base" responsive>
                <thead>
                    <tr>
                        <th colspan="2" className="table-dark">{base}</th>
                    </tr>
                </thead>
                <tbody>
                    {getRows(data, "base")}
                </tbody>
            </Table>
            {isVisibleExtra === "visible" &&
            <Table className="other {isVisibleExtra}" responsive>
                <thead>
                    <tr>
                        <th colspan="2" className="table-dark">{extra}</th>
                    </tr>
                </thead>
                <tbody>
                    {getRows(data, "extra")}
                </tbody>
            </Table>
            }
        </>
    )
}

export default FeeTable