import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../images/pexels-ron-lach-6714223.jpg"

const PageLayout = ({ title, subTitle, image = Hero, children, pageName = "common" }) => {
    return (
        <Layout>
            <SEO title={title} />
            <div className="page-wrapper">
                <h1>{title}</h1>

                {subTitle ?
                    <div className="hero-wrapper">
                        <img src={image} alt="アイキャッチ" />
                        <h2 className={pageName}>{subTitle}</h2>
                    </div>
                    : ""}
                {children}
            </div>
        </Layout >
    )
}

export default PageLayout